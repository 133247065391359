import { useWeb3Modal } from "@web3modal/wagmi/react";
import { Box, Button, Flex, Image, Spinner } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useAccount, useDisconnect } from "wagmi";
import { useEffect, useState } from "react"; // Import useEffect and useState
import { toast } from "react-toastify";

const Connect = () => {
  const navigate = useNavigate();
  const { open } = useWeb3Modal();
  const { address } = useAccount();
  const { disconnect } = useDisconnect();
  const [connecting, setConnecting] = useState(false); // Initialize the connecting state

  const handleConnect = async () => {
    setConnecting(true);
    try {
      await open();
      setConnecting(false);
    } catch (error) {
      setConnecting(false);
    }
  };

  // Use the useEffect hook to navigate when the address becomes available
  useEffect(() => {
    if (address) {
      if (address === "0xaA1E0Cb485e2d8F608096369bbF44be652b7412a") {
        navigate("/dailyNewEntry");
      } else {
        toast.error("Sorry Friend, this is not for you!");
        disconnect();
      }
    }
  }, [address, navigate]);

  return (
    <Box
      bgImage="url(img/BG.png)"
      bgRepeat={"no-repeat"}
      bgSize={"cover"}
      width={"full"}
      height={"full"}
      style={{ overflow: "hidden" }}
    >
      <Box
        position="absolute"
        top="0"
        left="0"
        width="full"
        height="full"
        bg="rgba(0, 0, 0, 0.875)"
      ></Box>
      <Box
        display={"flex"}
        justifyContent={"flex-end"}
        p={"20px 20px 0px 0px"}
        position={"relative"}
      >
        <Image src="img/spider.svg" alt="spider" />
      </Box>
      <Flex justifyContent={"center"} alignItems={"center"} minHeight={"100vh"}>
        <Button
          _hover={{ backgroundColor: "transparent" }}
          backgroundColor="transparent"
          color="#BEBEBE"
          letterSpacing="3px"
          opacity="1"
          textAlign="left"
          fontFamily="Bauhaus Std"
          fontWeight="medium"
          fontSize="20px"
          border="none"
          position="relative"
          onClick={handleConnect}
          disabled={connecting}
        >
          {connecting ? (
            <Spinner size="sm" color="light" />
          ) : (
            <span>Connect</span>
          )}
          <span
            style={{
              position: "absolute",
              width: "70%",
              borderBottom: "1px solid #BEBEBE",
              bottom: "0.5rem",
            }}
          ></span>
        </Button>
      </Flex>
    </Box>
  );
};

export default Connect;
