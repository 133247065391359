import { useEffect, useRef, useState } from "react";
import useScrollSnap from "react-use-scroll-snap";
import { Box } from "@chakra-ui/react";
import Header from "../components/Header";
import Quote from "../components/Quote";
import News from "../components/News";
import Photo from "../components/Photo";
import Footer from "../components/Footer";
import NFT from "../components/NFT";

export default function Home() {
  const [windowWidth, setWindowWidth] = useState(window?.innerWidth);

  const scrollRef = useRef(null);
  const scrollRefMobile = useRef(null);
  useScrollSnap({ ref: scrollRef, duration: 50 });

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    // Add event listener on component mount
    window.addEventListener("resize", handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box ref={windowWidth < 767 ? scrollRefMobile : scrollRef}>
      <Header scrollRef={scrollRef} />
      <Quote scrollRef={scrollRef} />
      <News scrollRef={scrollRef} />
      <NFT scrollRef={scrollRef} />
      <Photo scrollRef={scrollRef} />
      <Footer scrollRef={scrollRef} />
    </Box>
  );
}
