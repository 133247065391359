import {
  configureStore,
  combineReducers,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import entrySlice from "./slices/entrySlice";
import graphSlice from "./slices/graphSlice";
import saleSlice from "./slices/saleSlice";
import saleSuccessfullSlice from "./slices/saleSuccessfullSlice";

const combinedReducer = combineReducers({
  entry: entrySlice,
  graph:graphSlice,
  saleCreateds: saleSlice,
  saleSuccessfull: saleSuccessfullSlice
});
const rootReducer = (state: any, action: any) => {
  return combinedReducer(state, action);
};
export const store = configureStore({
  reducer: rootReducer,
  middleware: [...getDefaultMiddleware()],
});
