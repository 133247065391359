import { Box, Center, Flex, Image } from "@chakra-ui/react";

import useScrollSnap from "react-use-scroll-snap";
import { CustomCarousel } from "./CustomCarousel";
import { useSelector } from 'react-redux';

const NFT = (props: any) => {
  const { goto } = useScrollSnap({ ref: props.scrollRef, duration: 50 });
 



  return (
    <Box
      bgImage="url(img/BG.png)"
      bgRepeat="no-repeat"
      bgSize="cover"
      minH="100vh"
      maxH="100vh"
      position="relative"
      _before={{
        content: `""`,
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        background: "rgba(0, 0, 0, 0.875)",
      }}
    >
      <Center pos="relative" h="100vh" p="15px">
        <Flex
          pos="absolute"
          top="0"
          px="30px"
          cursor="pointer"
          onClick={() => goto(2)}
        >
          <Image
            src="img/top-line.png"
            alt="top-line"
            maxH={"260px"}
            height={"100px"}
          />
        </Flex>
        <Image
          src="img/down-arrow.svg"
          alt="bottom-line"
          pos="absolute"
          right={{
            base: "5vw",
            sm: "5vw",
            md: "15vw",
            lg: "23vw",
          }}
          top="0"
          mt={{ base: "8vh", lg: "12vh" }}
        />
        <CustomCarousel />
        <Box
          mr="50px"
          position="absolute"
          bottom="0"
          left={["25vw", "25vw", "35vw"]}
        >
          <Image
            src="img/up-arrow.svg"
            alt="bottom-line"
            maxH="35vw"
            pos="absolute"
            left="20%"
            bottom="60%"
          />
          <Image src="img/dots.svg" alt="bottom-line" maxW="14vw" mt="40px" />
        </Box>
        <Flex
          pos="absolute"
          bottom="0"
          alignItems="end"
          px="30px"
          cursor="pointer"
          onClick={() => goto(4)}
        >
          <Image
            src="img/bottom-line.png"
            alt="bottom-line"
            maxH={"260px"}
            height={"100px"}
          />
        </Flex>
      </Center>
    </Box>
  );
};

export default NFT;
