import { Box, Flex, Text } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { TwitterShareButton } from "react-share";

const Success = () => {
  const { newEntryForm } = useSelector((state: any) => state.entry);
  return (
    <Box
      bgImage="url(img/BG.png)"
      width={"full"}
      height={"full"}
      // onWheel={handleScroll}
      style={{ overflow: "hidden" }}
    >
      {/* Dark overlay */}
      <Box
        position="absolute"
        top="0"
        left="0"
        width="full"
        height="full"
        bg="rgba(0, 0, 0, 0.875)"
        // zIndex="1"
      ></Box>
      <Flex
        justifyContent={"center"}
        alignItems={"center"}
        minHeight={"100vh"}
        flexDirection={"column"}
        position={"relative"}
      >
        <Text
          color={"#BEBEBE"}
          fontWeight={"medium"}
          fontFamily={"Bauhaus Std"}
          letterSpacing={"4.5px"}
          fontSize={"45px"}
        >
          Success
        </Text>
        <TwitterShareButton
          title={newEntryForm?.tweet}
          url={"https://tableaux-three.vercel.app/"}
          hashtags={newEntryForm?.labels?.filter((item: any) => item !== "")}
        >
          <Text
            fontSize={"24px"}
            textDecoration={"underline"}
            fontWeight={"medium"}
            fontFamily={"Bauhaus Std"}
            letterSpacing={"2.4px"}
            color={"#BEBEBE"}
          >
            Tweet
          </Text>
        </TwitterShareButton>
      </Flex>
    </Box>
  );
};

export default Success;
