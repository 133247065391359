import React from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Link,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { toast } from "react-toastify";

const MailchimpForm = (props: any) => {
  const [formData, setFormData] = React.useState({
    email: "",
  });

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    props.onValidated({
      EMAIL: formData?.email,
    });
  };

  React.useEffect(() => {
    if (props?.status === "success") {
      toast.success(props?.message);
      setFormData({
        email: "",
      });
    } else if (props?.status === "error") {
      toast.error(props?.message);
    }
  }, [props.status]);

  return (
    <Box maxW="600px" mx="auto" p="4">
      <form onSubmit={handleSubmit}>
        <Flex justifyContent={"center"} alignItems={"center"} gap={"12px"}>
          <FormControl
            id="mce-EMAIL"
            isRequired
            display="flex"
            alignItems="center"
          >
            <FormLabel
              fontSize={"1.5vh"}
              color={"#FCFCFD"}
              letterSpacing={"0.01px"}
              fontFamily={"Nitti Typewriter"}
            >
              email
            </FormLabel>
            <Input
              placeholder="name@domain.com"
              height={"6vh"}
              border={"1px solid #FFFFFF3B"}
              borderRadius={"4px"}
              fontFamily={"Nitti Typewriter"}
              type="email"
              name="email"
              className="required email"
              id="mce-EMAIL"
              required
              value={formData.email}
              onChange={handleInputChange}
            />
          </FormControl>
          <Button
            type="submit"
            border={"1px solid #FEFCFD"}
            bg={"transparent"}
            borderRadius={"4px"}
            opacity={"0.5"}
            height={"5vh"}
            fontSize={"1.5vh"}
            letterSpacing={"1.5px"}
            color={"#FCFCFD"}
            textTransform={"uppercase"}
            padding={"1.2vh 3vh"}
            fontFamily={"Bauhaus Std"}
            disabled={props.status === "sending"}
          >
            {props.status === "sending" ? <Spinner size="sm" /> : "SUBSCRIBE"}
          </Button>
        </Flex>
      </form>
    </Box>
  );
};

export default MailchimpForm;
