import { Box, Text } from "@chakra-ui/react";
import React from "react";

const IsNotAdmin = () => {
  return (
    <Box
      top="0px"
      left="0px"
      width="100%"
      height="100vh"
      backgroundColor="#FFFFFF"
      opacity={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      fontFamily="Bauhaus Std"
      fontWeight="bold"
    >
      <Text
        textAlign="center"
        fontFamily="Bauhaus Std"
        fontWeight="bold"
        fontSize="100px"
        lineHeight="280px"
        letterSpacing="0px"
        color="#000000"
        opacity={1}
      >
        is not alex
      </Text>
    </Box>
  );
};

export default IsNotAdmin;
