import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Heading,
  Button,
  Text,
  Image,
  Center,
} from "@chakra-ui/react";

import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

// const [uploadStep, setUploadStep] = useState(0);
import { contractData, toWeiToDecimals } from "../web3/web3-utils";
import { useAccount, useContractWrite } from "wagmi";
import { useSelector } from "react-redux";
import html2canvas from "html2canvas";
import { IPFS_BASE_URL, PINATA_BASE_URL } from "../config";
const apiKey = "df1ec1ed30e28f6e6bd2"; // Replace with your actual Pinata API key
const apiSecret =
  "815404991b69b40e5d2533e74ac818688ec07daf81e7e5960abc0891debe8213";
const Publish = () => {
  const { address } = useAccount();
  const navigate = useNavigate();
  const location = useLocation();
  const { newEntryForm } = useSelector((state: any) => state.entry);
  const [uriOne, setUriOne] = useState("");
  const [uriTwo, setUriTwo] = useState("");
  const [isUploadComplete, setIsUploadComplete] = useState(false);

  const uploadImageToPinata = async (file: any) => {
    if (file) {
      const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`;
      const formData = new FormData();
      formData.append("file", file);
      try {
        const response = await axios.post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            pinata_api_key: apiKey,
            pinata_secret_api_key: apiSecret,
          },
        });

        // Handle the response from Pinata, which will contain the IPFS CID (Content Identifier) of the pinned file.
        // console.log('Uploaded to Pinata. IPFS CID:', `https://gateway.pinata.cloud/ipfs/${response.data.IpfsHash}`);
        const imageurl = `${PINATA_BASE_URL}/ipfs/${response.data.IpfsHash}`;
        return imageurl;
      } catch (error) {
        console.error("Error uploading to Pinata:", error);
        return "";
      }
    } else {
      console.error("No image selected for upload");
      return "";
    }
  };

  const dataURLtoFile = (base64: any, filename: any) => {
    var arr = base64.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const getPreviewImage = async () => {
    const input = document.getElementById("previewToPrint");
    if (input) {
      try {
        const canvas = await html2canvas(input, { scale: 2 });
        const data = canvas.toDataURL("image/png");
        const file = dataURLtoFile(data, "previewImage");

        // download file to local
        // var link = document.createElement("a");
        // link.download = "image.png";
        // link.href = data;
        // link.click();
        // link.remove();

        // return "";

        const response = await uploadImageToPinata(file);
        return response;
      } catch (err: any) {
        console.log("error while htmlcanvas", err);
        return "";
      }
    } else {
      return "";
    }
  };

  const getFullImage = async () => {
    const input = document.getElementById("nftToPrint");
    if (input) {
      try {
        const canvas = await html2canvas(input, { scale: 2 });
        const data = canvas.toDataURL("image/png");
        const file = dataURLtoFile(data, "nftImage");
        const response = await uploadImageToPinata(file);
        return response;
      } catch (err: any) {
        console.log("error while htmlcanvas", err);
        return "";
      }
    } else {
      return "";
    }
  };

  const uploadJsonToPinata = async (formData1: any, formData2: any) => {
    const url = "https://api.pinata.cloud/pinning/pinJSONToIPFS";
    try {
      const response1 = await axios.post(url, formData1, {
        headers: {
          "Content-Type": "application/json",
          pinata_api_key: apiKey,
          pinata_secret_api_key: apiSecret,
        },
      });

      setUriOne(`${PINATA_BASE_URL}/ipfs/${response1.data.IpfsHash}`);
      // console.log('Uploaded JSON to Pinata 1. IPFS CID:', response1.data.IpfsHash);

      const response2 = await axios.post(url, formData2, {
        headers: {
          "Content-Type": "application/json",
          pinata_api_key: apiKey,
          pinata_secret_api_key: apiSecret,
        },
      });
      setUriTwo(`${PINATA_BASE_URL}/ipfs/${response2.data.IpfsHash}`);
      setIsUploadComplete(true);
      // console.log('Uploaded JSON to Pinata 2. IPFS CID:', response2.data.IpfsHash);
    } catch (error) {
      console.error("Error uploading JSON to Pinata:", error);
    }
  };

  const { write: createNewEntry, isLoading: isCreateNewEntryLoading } =
    useContractWrite({
      abi: contractData?.NovelTableauxVivantsFactoryABI,
      address: contractData?.NovelTableAuxvivantsFactoryAddress,
      functionName: "createNewEntry",
      args: [
        1,
        newEntryForm?.copies,
        uriOne,
        uriTwo,
        toWeiToDecimals(newEntryForm?.price.toString(), 18),
        0,
        newEntryForm?.labels?.map((item: string) => item?.toLowerCase()) || [],
        newEntryForm?.notation,
      ],
      onSuccess: (result: any) => {
        toast.success("Published successful!"); // Show success toast
        navigate("/success");
      },
      onError: (error: any) => {
        toast.error(error?.message);
      },
    });

  useEffect(() => {
    if (!newEntryForm) {
      navigate("/dailyNewEntry");
    } else if (newEntryForm) {
      const getPinataResponse = async () => {
        const nftImage = await getFullImage();
        const previewImage = await getPreviewImage();
        if (nftImage && previewImage) {
          const tempLabels = newEntryForm?.labels?.filter(
            (item: any) => item !== ""
          );
          const tweetForm = {
            name: newEntryForm?.notation,
            notation: newEntryForm?.notation,
            tweet: newEntryForm?.tweet,
            labels: tempLabels,
            price: newEntryForm?.price,
            isCompleted: newEntryForm?.isCompleted,
            image: previewImage,
            attributes: tempLabels?.map((label: any) => {
              return { trait_type: "Label", value: label };
            }),
          };
          const detailForm = {
            ...newEntryForm,
            name: newEntryForm?.notation,
            labels: tempLabels,
            attributes: tempLabels?.map((label: any) => {
              return { trait_type: "Label", value: label };
            }),
            image: nftImage,
          };
          uploadJsonToPinata(tweetForm, detailForm);
        }
      };
      getPinataResponse();
    }
  }, [newEntryForm]);

  useEffect(() => {
    if (!address) {
      navigate("/connect");
    }
  }, []);

  return (
    <Box
      bgImage="url(img/BG.png)"
      bgRepeat={"no-repeat"}
      bgSize={"cover"}
      width={"full"}
      height={"full"}
      // onWheel={handleScroll}
      style={{ overflow: "hidden" }}
    >
      <Box
        position="absolute"
        top="0"
        left="0"
        width="full"
        height="full"
        bg="rgba(0, 0, 0, 0.875)"
        // zIndex="1"
      ></Box>
      <Box p={"102px 10% 60px 10%"} position={"relative"}>
        <Heading
          fontSize={"30px"}
          fontWeight={"medium"}
          fontFamily={"Bauhaus Std"}
          letterSpacing={"3px"}
          color={"#BEBEBE"}
          textAlign={"left"}
          marginBottom={"91px"}
        >
          daily entry
        </Heading>
        <Flex
          justify={"center"}
          align={{ base: "center", md: "start" }}
          gap="20"
          flexDirection={{ base: "column", md: "row" }}
        >
          <Box>
            <Box
              id="nftToPrint"
              border={"1px solid #707070"}
              width="380px"
              minW="380px" // padding={["20px 15px", "38px 30px"]}
              padding={"35px 25px"}
              color={"#BEBEBE"}
              background={"#0B0A08 0% 0% no-repeat padding-box"}
            >
              <Flex justifyContent={"space-between"}>
                <Heading fontSize={"12px"} fontWeight={"normal"}>
                  {newEntryForm?.notation}
                </Heading>
                {/* <Heading fontSize={"12px"} fontWeight={"normal"}>
                  #1/{newEntryForm?.copies}
                </Heading> */}
              </Flex>
              <Box
                textAlign={"left"}
                marginTop={"26px"}
                height={"40%"}
                overflow={"hidden"}
                fontSize={"12px"}
              >
                <Box minH="78px">
                  <Text marginBottom={"15px"} color={"#FEFCFD"}>
                    {newEntryForm?.tweet}
                  </Text>
                </Box>
                <Box minH="172px">
                  <Text marginBottom={"15px"}>{newEntryForm?.additional}</Text>
                </Box>
              </Box>
              {newEntryForm?.imageUri ? (
                <Image src={newEntryForm?.imageUri} alt="image" />
              ) : (
                ""
              )}
              <Flex
                justifyContent={"center"}
                fontSize={"12px"}
                gap={"22px"}
                marginTop={"25px"}
              >
                {newEntryForm?.labels
                  ?.filter((item: any) => item !== "")
                  ?.map((entry: any, idx: any) => (
                    <Text key={idx}>#{entry}</Text>
                  ))}
              </Flex>
            </Box>
            <Box
              border={"1px solid #707070"}
              width="380px"
              minW="380px"
              padding={"17px 117px"}
              color={"#BEBEBE"}
            >
              <Button
                bg={"transparent"}
                _hover={{
                  background: "transparent",
                }}
                onClick={() => navigate("/dailyNewEntry")}
              >
                <Image
                  src="img/pencil.png"
                  filter="brightness(100%) saturate(100%) invert(100%) sepia(100%) saturate(100%) hue-rotate(195deg) brightness(100%) contrast(100%)"
                  width={"20px"}
                  height={"20px"}
                />
              </Button>
            </Box>
            <Box position={"relative"}>
              <Button
                marginTop={"105px"}
                border={"1px solid #FEFCFD"}
                borderRadius={"4px"}
                opacity={"0.5"}
                fontSize={"15px"}
                fontWeight={"medium"}
                fontFamily={"Bauhaus Std"}
                letterSpacing={"1.5px"}
                textTransform={"uppercase"}
                color={"#FEFCFD"}
                //   width={"60px"}
                p={"13px 73px"}
                bg={"transparent"}
                _hover={{
                  bg: "#59C1CC 0% 0% no-repeat padding-box",
                }}
                disabled={!isUploadComplete || isCreateNewEntryLoading}
                isLoading={!isUploadComplete || isCreateNewEntryLoading}
                onClick={() => createNewEntry()}
              >
                Publish
              </Button>
            </Box>
          </Box>
          <Box
            id="previewToPrint"
            border={"1px solid #707070"}
            width="380px"
            minW="380px"
            padding={["20px 15px", "30px 20px"]}
            color={"#BEBEBE"}
            background={"#0B0A08 0% 0% no-repeat padding-box"}
            height="100%"
          >
            <Flex justifyContent={"space-between"}>
              <Heading fontSize={"13px"} fontWeight={"normal"}>
                {newEntryForm?.notation || ""}
              </Heading>
            </Flex>
            <Box
              textAlign={"left"}
              marginTop={"35px"}
              height={"40%"}
              overflow={"hidden"}
              fontSize={"1.5vh"}
            >
              <Text marginBottom={"15px"} minH="78px">
                {newEntryForm?.tweet || ""}
              </Text>

              <Box pos="relative" minH="172px">
                <Box
                  position="absolute"
                  // left={left}
                  width={"100%"}
                  bg="#252423"
                  backdropFilter={"blur(4px)"}
                  height={"100%"}
                >
                  <Center h="100%">
                    <Box>
                      <Image
                        src="img/lock.png"
                        display={"block"}
                        marginLeft={"auto"}
                        marginRight={"auto"}
                      />
                    </Box>
                  </Center>
                </Box>
                <Text>
                  {newEntryForm?.additional?.length > 550
                    ? newEntryForm?.additional?.slice(0, 550) + "....."
                    : newEntryForm?.additional || " "}
                </Text>
              </Box>
            </Box>
            <Flex
              justifyContent={"center"}
              fontSize={"14px"}
              gap={"22px"}
              marginTop={"30px"}
            >
              {newEntryForm?.labels
                ?.filter((item: any) => item !== "")
                ?.map((item: any, idx: number) => (
                  <Text key={idx}>#{item}</Text>
                ))}
            </Flex>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default Publish;
