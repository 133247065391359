import * as React from "react";
import { ChakraProvider, Box } from "@chakra-ui/react";

import Home from "./pages/Home";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"; // Import the necessary components from react-router-dom
import Buy from "./pages/Buy";
import theme from "./components/Theme";
import Connect from "./components/Connect";
import AddNewEntry from "./components/AddNewEntry";
import DailyEntry from "./components/DailyEntry";
import Publish from "./components/Publish";
import Success from "./components/Success";
import IsAdmin from "./components/IsAdmin";
import IsNotAdmin from "./components/IsNotAdmin";
import Fonts from "./components/Fonts";
import { useDispatch, useSelector } from "react-redux";
import { LOAD_QUERRY } from "./apolloSetup/query";
import { useQuery } from "@apollo/client";
import { fetchAllNFTs } from "./web3/fetchAllNFTs";
import { toggleRefetch } from "./store/slices/entrySlice";
import { useAccount } from "wagmi";

export const App = () => {
  const dispatch = useDispatch();
  const { isConnected } = useAccount();
  const { isRefetch } = useSelector((state: any) => state.entry);
  const { loading, data, refetch } = useQuery(LOAD_QUERRY);

  React.useEffect(() => {
    if (isRefetch) {
      refetch();
      dispatch(toggleRefetch(false));
    }
  }, [isRefetch]);

  React.useEffect(() => {
    if (!isConnected) {
      fetchAllNFTs(data, loading, dispatch);
    }
  }, [data, loading, isConnected]);

  return (
    <ChakraProvider theme={theme}>
      <Fonts />
      <Box textAlign="center" fontSize="xl">
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/buy/:id" element={<Buy />} />
            <Route path="/connect" element={<Connect />} />
            <Route path="/addNewEntry" element={<AddNewEntry />} />
            <Route path="/dailyNewEntry" element={<DailyEntry />} />
            <Route path="/publish" element={<Publish />} />
            <Route path="/success" element={<Success />} />
            <Route path="/isAdmin" element={<IsAdmin />} />
            <Route path="/isNotAdmin" element={<IsNotAdmin />} />
          </Routes>
        </Router>
      </Box>
    </ChakraProvider>
  );
};
