import { Box, Button, Flex, Image, Spinner, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import NewsCard from "../components/NewsCard";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { contractData } from "../web3/web3-utils";
import { useAccount, useContractWrite } from "wagmi";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import axios from "axios";
import { parseEther } from "viem";
import FullNft from "../components/FullNft";
import FbIco from "../assets/img/fb.png";
import TwitterIco from "../assets/img/twitter.jpeg";
import RedditIco from "../assets/img/reddit.png";
import {
  FacebookShareButton,
  RedditShareButton,
  TwitterShareButton,
} from "react-share";
import { fetchMyNFT } from "../web3/fetchMyNFTs";

const Buy = () => {
  const { id } = useParams();
  const { open } = useWeb3Modal();
  const { isConnected, address } = useAccount();
  const { entries } = useSelector((state: any) => state.entry);

  const [success, setSuccess] = useState(false);
  const [ethLoading, setEthLoading] = useState(false);
  const [ethPrice, setEthPrice] = useState("");
  const [selectedEntry, setSelectedEntry] = useState<any>(null);
  const [nftInfo, setNftInfo] = useState<any>(null);

  const selectedEntryPrice = selectedEntry?.uriData?.price?.toString() || "0";
  const value = parseEther(selectedEntryPrice);

  const getNFTInfo = async () => {
    fetchMyNFT(selectedEntry?.entry?.nftContract, id, address)
      .then((res: any) => {
        setNftInfo(res);
      })
      .catch((err: any) => {
        console.log("error", err);
      });
  };

  const { write: buy, isLoading: buyLoading } = useContractWrite({
    address: contractData?.NovelTableauxVivantsMarketplaceAddress,
    abi: contractData?.NovelTableauxVivantsMarketplaceAbi,
    functionName: "buy",
    args: [id, 1],
    value: value,
    onSuccess: () => {
      getNFTInfo();
      setSuccess(true);
    },
  });

  const getEthPrice = async () => {
    try {
      setEthLoading(true);
      let { data } = await axios.get(
        "https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=usd"
      );
      setEthPrice(data?.["matic-network"]?.usd);
      setEthLoading(false);
    } catch (e) {
      setEthLoading(false);
    }
  };

  useEffect(() => {
    let tempEntry = entries?.find((entry: any) => entry?.entry?.tokenId == id);
    if (tempEntry) {
      setSelectedEntry(tempEntry);
    }
  }, [entries]);

  useEffect(() => {
    if (nftInfo) {
      setSuccess(true);
    }
  }, [nftInfo]);

  useEffect(() => {
    getEthPrice();
  }, []);

  return (
    <Box
      top={"0px"}
      left={"0px"}
      width={"full"}
      minH={"100vh"}
      background="#0B0A08 0% 0% no-repeat padding-box"
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Box
        position="absolute"
        top="0"
        left="0"
        width="100%"
        height="full"
        backgroundImage={"url(img/blur-bg.png)"}
        backgroundRepeat={"no-repeat"}
        backgroundSize={"cover"}
      ></Box>
      <Box
        position={"relative"}
        marginY={["10px", "20px", "40px", "80px"]}
        marginX={"15%"}
        maxW={"1221px"}
        height={"full"}
        background={"#0B0A08 0% 0% no-repeat padding-box"}
        border={"1px solid #707070"}
        paddingY={["8px", "16px", "24px", "32px", "64px"]}
        paddingX={["10px", "30px", "60px", "96px"]}
        display={"flex"}
        flexDir={["column", "column", "column", "column", "row"]} // Specify the desired flex direction for each breakpoint
        gap={["40px", "30px", "20px", "10px", "0px"]}
      >
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
          {success ? (
            <FullNft nft={nftInfo} />
          ) : (
            <NewsCard nft={selectedEntry} success={success} btnHide="true" />
          )}
        </Box>
        {success ? (
          <>
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              paddingLeft={["0px", "0px", "0px", "0px", "120px"]}
              color={"#BEBEBE"}
              gap={"25px"}
            >
              <Text
                fontSize={"45px"}
                fontFamily={"Bauhaus Std"}
                letterSpacing={"4.5px"}
              >
                Success
              </Text>
              <Text
                fontSize={"12px"}
                width={"78  %"}
                fontFamily={"Nitti Typewriter"}
              >
                {selectedEntry?.uriData?.tweet || ""}
              </Text>
              <Button
                border={"1px solid #FEFCFD"}
                borderRadius={"4px"}
                background={"transparent"}
                color={"#FCFCFD"}
                textTransform={"uppercase"}
                letterSpacing={"1.5px"}
                fontSize={"15px"}
                fontFamily={"Bauhaus Std"}
                paddingX={"60px"}
                marginTop={"25px"}
                fontWeight={"medium"}
                _hover={{
                  bg: "#59C1CC",
                }}
                onClick={() =>
                  window?.open(
                    `https://testnets.opensea.io/assets/mumbai/0x04e1f6591259cadb4b2f9972f8107e4aeca7da4f/${id}`
                  )
                }
              >
                See Your NFT
              </Button>
              <Text
                fontSize={"12px"}
                letterSpacing={"1.2px"}
                marginTop={"28px"}
                fontFamily={"Bauhaus Std"}
              >
                Share
              </Text>
              <Flex marginTop={"-12px"} gap={"15px"}>
                <TwitterShareButton
                  title={selectedEntry?.uriData?.tweet}
                  url={"https://tableaux-three.vercel.app/"}
                  hashtags={selectedEntry?.uriData?.labels}
                >
                  <Image src={TwitterIco} width={"35px"} height={"35px"} />
                </TwitterShareButton>
                <FacebookShareButton
                  url="https://tableaux-three.vercel.app/"
                  hashtag={selectedEntry?.uriData?.labels}
                >
                  <Image src={FbIco} width={"35px"} height={"35px"} />
                </FacebookShareButton>
                <RedditShareButton
                  title={selectedEntry?.uriData?.tweet}
                  url="https://tableaux-three.vercel.app/"
                >
                  <Image
                    src={RedditIco}
                    width={"35px"}
                    height={"35px"}
                    bg={"#FFF"}
                    border={"none"}
                  />
                </RedditShareButton>
              </Flex>
            </Box>
          </>
        ) : (
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"flex-start"}
            paddingLeft={["0px", "0px", "0px", "0px", "120px"]}
            color={"#BEBEBE"}
            fontFamily={"Nitti Typewriter"}
          >
            <Text letterSpacing={"0.01px"} fontSize={"16px"}>
              Buy now
            </Text>
            <Flex marginBottom={"20px"}>
              <Text letterSpacing={"0.02px"} fontSize={"42px"}>
                {selectedEntry?.uriData?.price || 0}
                <Text
                  fontSize={"20px"}
                  letterSpacing={"0.01px"}
                  display={"inline-block"}
                >
                  /$
                  {ethLoading ? (
                    <Spinner size="sm" />
                  ) : (
                    (
                      Number(selectedEntry?.uriData?.price) * Number(ethPrice)
                    )?.toFixed(3) || 0
                  )}
                </Text>
              </Text>
            </Flex>
            <Button
              border={"1px solid #FEFCFD"}
              borderRadius={"4px"}
              background={"transparent"}
              color={"#FCFCFD"}
              textTransform={"uppercase"}
              letterSpacing={"1.5px"}
              fontSize={"15px"}
              fontFamily={"Bauhaus Std"}
              paddingX={"60px"}
              fontWeight={"medium"}
              _hover={{
                bg: "#59C1CC",
              }}
              isLoading={buyLoading}
              isDisabled={buyLoading}
              onClick={() => {
                if (isConnected) {
                  buy();
                } else {
                  open();
                }
              }}
            >
              Buy With Crypto
            </Button>

            <Box
              marginTop={"48px"}
              display={"flex"}
              gap={"28px"}
              // justifyContent={"flex-start"}
              fontSize={"12px"}
            >
              <Text>Description</Text>
              <Text textAlign={"left"} paddingRight={"10px"}>
                {selectedEntry?.uriData?.tweet || ""}
              </Text>
            </Box>
            <Box
              display={"flex"}
              gap={"50px"}
              fontSize={"12px"}
              marginTop={"27px"}
            >
              <Text>Contract</Text>
              <Text>
                {" "}
                {selectedEntry?.entry?.nftContract?.replace(
                  selectedEntry?.entry?.nftContract?.slice(5, 38),
                  "..."
                )}{" "}
              </Text>
            </Box>
            <Box
              display={"flex"}
              gap={"60px"}
              fontSize={"12px"}
              marginTop={"27px"}
            >
              <Text>Edition</Text>
              <Text>
                #
                {selectedEntry?.entry?.soldAmount
                  ? Number(selectedEntry?.entry?.amount) -
                    (Number(selectedEntry?.entry?.amount) -
                      Number(selectedEntry?.entry?.soldAmount)) +
                    1
                  : 1 || 0}
              </Text>
            </Box>
            <Box
              display={"flex"}
              gap={"50px"}
              fontSize={"12px"}
              marginTop={"27px"}
            >
              <Text>Editions</Text>
              <Text>
                {" "}
                {selectedEntry?.entry?.soldAmount
                  ? Number(selectedEntry?.entry?.amount) -
                    (Number(selectedEntry?.entry?.amount) -
                      Number(selectedEntry?.entry?.soldAmount)) +
                    1
                  : 1 || 0}{" "}
                of {selectedEntry?.entry?.amount || 0}
              </Text>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Buy;
