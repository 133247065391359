// import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { ApolloClient, InMemoryCache, HttpLink, from } from "@apollo/client";

const link = from([
  new HttpLink({
    uri: "https://api.studio.thegraph.com/query/57309/tableauxv8/version/latest",
  }),
]);

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: link,
});

export default client;
