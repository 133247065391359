import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  nftContract: null,
  tokenId: null,
};

const saleSlice = createSlice({
  name: "sale",
  initialState,
  reducers: {
    setSaleData: (state, action) => {
      if (action?.payload) {
        const { nftContract, tokenId } = action?.payload;
        state.nftContract = nftContract;
        state.tokenId = tokenId;
      }
    },
  },
});

export const { setSaleData } = saleSlice.actions;

export default saleSlice.reducer;
