import { Box, Center, Flex, Heading, Image, Text } from "@chakra-ui/react";
import CustomButton from "./CustomButton";
import useScrollSnap from "react-use-scroll-snap";

const Header = (props: any) => {
  const { goto } = useScrollSnap({ ref: props.scrollRef, duration: 50 });
  return (
    <>
      <Box
        bgImage="url(img/BG.png)"
        bgRepeat="no-repeat"
        bgSize="cover"
        minH="100vh"
        maxH="100vh"
        position="relative"
        _before={{
          content: `""`,
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          background: "rgba(0, 0, 0, 0.875)",
        }}
      >
        <Center pos="relative" h="100vh" p="15px">
          <Flex justifyContent="end" pos="absolute" top="0" right="0" p="15px">
            <Image src="img/spider.svg" alt="spider" />
          </Flex>
          <Box color="#BEBEBE" textAlign="left">
            <Heading
              textAlign={"left"}
              letterSpacing={"12px"}
              lineHeight="1"
              fontSize="5vw"
              fontWeight="550"
              textTransform={"uppercase"}
              fontFamily={"Bauhaus Std"}
              mb="0"
            >
              TABLEAUX
            </Heading>
            <Heading
              textAlign={"left"}
              letterSpacing={"12px"}
              lineHeight="1"
              fontSize="5vw"
              fontWeight="550"
              textTransform={"uppercase"}
              fontFamily={"Bauhaus Std"}
              mb="0"
            >
              VIVANTS
            </Heading>
            <Text
              textAlign="left"
              fontSize="1.5vw"
              lineHeight="44px"
              letterSpacing="0px"
              fontFamily={"Nitti Typewriter"}
              mt={{ md: "15px" }}
            >
              The Dictatorship of Freedom.
            </Text>
            <Flex
              marginTop={["46px", "56px", "66px", "96px"]}
              justify={"center"}
            >
              <CustomButton title="Read" handleFunction={() => goto(2)} />
            </Flex>
          </Box>
          <Box mt={["0vh", "10vh", "20vh"]}>
            <div className="circle">
              <Image
                src="img/anim-character.svg"
                alt="anim"
                className="circle-img"
              />
            </div>

            {/* <Image src="img/animation.svg" alt="anim" /> */}
          </Box>
          <Flex
            pos="absolute"
            bottom="0"
            px="30px"
            cursor="pointer"
            onClick={() => goto(1)}
          >
            <Image
              src="img/bottom-line.png"
              alt="bottom-line"
              maxH={"260px"}
              height={"100px"}
            />
          </Flex>
        </Center>
      </Box>
    </>
  );
};

export default Header;
