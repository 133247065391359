import {
  Box,
  Button,
  Center,
  Checkbox,
  Container,
  Flex,
  Heading,
  Image,
  Input,
  Spinner,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import NewsCard from "./NewsCard";
import useScrollSnap from "react-use-scroll-snap";
import { Swiper, SwiperSlide } from "swiper/react";
import { useDispatch, useSelector } from "react-redux";

// Import Swiper styles
import "swiper/css";
import "swiper/css/scrollbar";
import NewsSkeletonCard from "./NewSkeletonCard";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useAccount, useDisconnect } from "wagmi";
import { toggleRefetch } from "../store/slices/entrySlice";
import { fetchMyNFTs } from "../web3/fetchMyNFTs";

const News = (props: any) => {
  const dispatch = useDispatch();
  const { open } = useWeb3Modal();
  const { isConnected, address } = useAccount();
  const { disconnect } = useDisconnect();

  const { entries, entriesLoading } = useSelector((state: any) => state.entry);

  const swiperRef = useRef<any>();

  const { goto } = useScrollSnap({ ref: props.scrollRef, duration: 50 });

  const [searchValue, setSearchValue] = useState("");

  const filteredEntries = entries?.filter((entry: any) => {
    // Check if the input text is found in the notation, labels, or tweet
    const matchesNotation = entry?.uriData?.notation
      ?.toLowerCase()
      ?.includes(searchValue?.toLowerCase());
    const matchesLabels = entry?.uriData?.labels?.some((label: any) =>
      label?.toLowerCase()?.includes(searchValue?.toLowerCase())
    );
    // Return true if any of the checks match
    return matchesNotation || matchesLabels;
  });

  useEffect(() => {
    if (isConnected) {
      fetchMyNFTs(entries, dispatch, address);
    }
  }, [isConnected]);

  return (
    <>
      <Box
        bgImage="url(img/BG.png)"
        bgRepeat="no-repeat"
        bgSize="cover"
        minH="100vh"
        maxH="100vh"
        position="relative"
        _before={{
          content: `""`,
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          background: "rgba(0, 0, 0, 0.875)",
        }}
      >
        <Center pos="relative" h="100vh" p="15px">
          <Flex
            pos="absolute"
            top="0"
            px="30px"
            cursor="pointer"
            onClick={() => goto(1)}
          >
            <Image
              src="img/top-line.png"
              alt="top-line"
              maxH={"260px"}
              height={"100px"}
            />
          </Flex>
          <Container maxW={{ md: "80vw" }}>
            <Flex
              justifyContent="space-between"
              flexDir={["column", "column", "row"]}
              alignItems={["flex-start", "flex-start", "center"]}
              w="100%"
            >
              <Flex alignItems="center" mt="-15px">
                <Heading
                  color={"#BEBEBE"}
                  display={"inline"}
                  fontSize={"22px"}
                  marginTop={"20px"}
                  fontWeight={"normal"}
                >
                  latest
                </Heading>
                <Button
                  padding={"0px"}
                  mt="15px"
                  marginEnd={"-15px"}
                  onClick={() => {
                    if (swiperRef?.current) {
                      swiperRef?.current?.slidePrev();
                    }
                  }}
                  bg={"transparent"}
                  _hover={{ backgroundColor: "transparent" }}
                >
                  <Image src="img/bx-right-arrow.png" />
                </Button>
                <Button
                  padding={"0px"}
                  mt="15px"
                  onClick={() => {
                    if (swiperRef?.current) {
                      swiperRef?.current?.slideNext();
                    }
                  }}
                  bg={"transparent"}
                  _hover={{ backgroundColor: "transparent" }}
                >
                  <Image
                    src="img/bx-right-arrow.png"
                    transform="rotate(180deg)"
                  />{" "}
                </Button>
              </Flex>
              <Box position={"relative"} color={"#707070"}>
                <Flex gap={"26.6px"} align={"center"}>
                  <Image
                    src="img/search.png"
                    alt="searchbar"
                    width={"16px"}
                    height={"16px"}
                  />
                  <Input
                    placeholder="search address, hashtag or date"
                    width={"241px"}
                    height={"22px"}
                    p="8px"
                    fontSize={"12px"}
                    fontFamily={"Nitti TypeWriter"}
                    bg="rgba(255, 255, 255, 0.12)"
                    border={"none"}
                    borderRadius={"none"}
                    color="rgba(172, 169, 169, 1)"
                    // bg={"#FFFFFF 0% 0% no-repeat padding-box"}
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                </Flex>
              </Box>
            </Flex>
            <Flex mt="10px">
              <Checkbox
                color="#BEBEBE"
                isChecked={isConnected}
                onChange={(e) => {
                  if (e.target?.checked) {
                    open();
                  } else {
                    dispatch(toggleRefetch(true));
                    disconnect();
                  }
                }}
              >
                My NFT's
              </Checkbox>
            </Flex>
            <Box mt="30px">
              {!entriesLoading && filteredEntries?.length < 1 ? (
                <Box color="#fff" fontSize="16px" fontWeight="800">
                  No Entries Found
                </Box>
              ) : (
                <Swiper
                  spaceBetween={30}
                  slidesPerView={"auto"}
                  scrollbar={{ draggable: true }}
                  onSwiper={(swiper: any) => {
                    swiperRef.current = swiper;
                  }}
                  className="mySwiper"
                >
                  {entriesLoading
                    ? new Array(4)?.fill(1)?.map((_, idx) => (
                        <SwiperSlide key={idx}>
                          <NewsSkeletonCard />
                        </SwiperSlide>
                      ))
                    : filteredEntries?.map((entry: any, idx: number) => (
                        <SwiperSlide key={idx}>
                          <NewsCard nft={entry} />
                        </SwiperSlide>
                      ))}
                </Swiper>
              )}
            </Box>
          </Container>
          <Flex
            pos="absolute"
            bottom="0"
            px="30px"
            cursor="pointer"
            onClick={() => goto(3)}
          >
            <Image
              src="img/bottom-line.png"
              alt="bottom-line"
              maxH={"260px"}
              height={"100px"}
            />
          </Flex>
        </Center>
      </Box>
    </>
  );
};

export default News;
