import { ColorModeScript } from "@chakra-ui/react";
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import "./index.css";

import { WagmiConfig } from "wagmi";
import { wagmiConfig } from "./web3/web3-utils";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import { store } from "./store";
import { ApolloProvider } from "@apollo/client";
import client from "./apolloSetup/apolloClient";

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(container);

root.render(
  <React.StrictMode>
    <WagmiConfig config={wagmiConfig}>
      <ApolloProvider client={client}>
        <Provider store={store}>
          <ToastContainer />
          <ColorModeScript />
          <App />
        </Provider>
      </ApolloProvider>
    </WagmiConfig>
  </React.StrictMode>
);

serviceWorker.unregister();

reportWebVitals();
