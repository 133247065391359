import {
  AbsoluteCenter,
  Box,
  Center,
  Flex,
  Heading,
  Image,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import CustomButton from "./CustomButton";
import { useNavigate } from "react-router-dom";
import LockIco from "../assets/img/lock.png";

const NewsCard = ({ left, success, btnHide, nft }: any) => {
  const [showBtn, setShowBtn] = useState(true);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/buy/${nft?.entry?.tokenId}`);
    setShowBtn(false);
  };

  return (
    <Box
      border={"1px solid #707070"}
      width={["400px"]}
      padding={["38px 30px"]}
      color={"#BEBEBE"}
      background={"#0B0A08 0% 0% no-repeat padding-box"}
      // height="100%"
      minH="472px"
    >
      <Flex justifyContent={"space-between"}>
        <Heading fontSize={"13px"} fontWeight={"normal"}>
          {nft?.uriData?.notation || ""}
        </Heading>
        <Heading fontSize={"13px"} fontWeight={"normal"}>
          #{" "}
          {nft?.entry?.soldAmount
            ? Number(nft?.entry?.amount) -
              (Number(nft?.entry?.amount) - Number(nft?.entry?.soldAmount)) +
              1
            : 1 || 0}
          /{nft?.entry?.amount || 0}
          {/* #{nft?.entry?.soldAmount || 0}/{nft?.entry?.amount || 0} */}
        </Heading>
      </Flex>
      <Box
        textAlign={"left"}
        marginTop={"35px"}
        height={"40%"}
        overflow={"hidden"}
        fontSize={"10px"}
      >
        <Box minH="78px" marginBottom={"15px"}>
          <Text>{nft?.uriData?.tweet || ""}</Text>
        </Box>

        <Box pos="relative" minH="172px">
          <Box
            position="absolute"
            left={left}
            width={"100%"}
            bg="rgba(0,0,0, 0.2)"
            backdropFilter={success || nft?.isOwner ? "" : "blur(4px)"}
            height={"100%"}
          >
            <Center h="100%">
              <Box>
                {success || nft?.isOwner ? (
                  ""
                ) : (
                  <Image
                    src={LockIco}
                    alt="lock"
                    display={"block"}
                    marginLeft={"auto"}
                    marginRight={"auto"}
                  />
                )}
                {!showBtn || success || btnHide ? (
                  ""
                ) : (
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    mt={{ lg: "15px" }}
                  >
                    <CustomButton
                      title={
                        Number(nft?.entry?.amount) ==
                        Number(nft?.entry?.soldAmount)
                          ? "Sold"
                          : "Read More"
                      }
                      handleFunction={
                        Number(nft?.entry?.amount) ==
                        Number(nft?.entry?.soldAmount)
                          ? () => {}
                          : handleClick
                      }
                      size="16px"
                    />
                  </Box>
                )}
              </Box>
            </Center>
          </Box>
          {nft?.uriData?.additional ? (
            <Text marginBottom={"15px"}>
              {nft?.uriData?.additional?.length > 450
                ? nft?.uriData?.additional?.slice(0, 450) + "....."
                : nft?.uriData?.additional || " "}
            </Text>
          ) : (
            <>
              <Text marginBottom={"15px"}>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
                commodo ligula eget dolor. Aenean massa. Cum sociis natoque mus.
              </Text>
              <Text marginBottom={"15px"}>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
                commodo ligula eget dolor. Aenean massa. Cum sociis natoque
              </Text>
              <Text marginBottom={"15px"}>
                Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
                commodo ligula eget dolor. Aenean massa. Cum sociis natoque
                penatibus et magnis dis parturient montes, nascetur ridiculus
                mus.
              </Text>
            </>
          )}
        </Box>
      </Box>
      <Flex
        justifyContent={"center"}
        fontSize={"14px"}
        gap={"22px"}
        marginTop={"30px"}
      >
        {nft?.uriData?.labels?.map((item: any, idx: number) => (
          <Text key={idx}>#{item}</Text>
        ))}
      </Flex>
    </Box>
  );
};

export default NewsCard;
