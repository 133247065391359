import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  newEntryForm: null,
  entries: null,
  entriesLoading: false,
  isRefetch: false,
};
export const counterSlice = createSlice({
  name: "entry",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    addNewEntry: (state, action) => {
      state.newEntryForm = action?.payload;
    },
    setEntries: (state, action) => {
      state.entries = action?.payload;
    },
    toggleEntriesLoading: (state, action) => {
      state.entriesLoading = action?.payload;
    },
    toggleRefetch: (state, action) => {
      state.isRefetch = action?.payload;
    },
  },
});
export const { addNewEntry, setEntries, toggleEntriesLoading, toggleRefetch } =
  counterSlice.actions;
export default counterSlice.reducer;
