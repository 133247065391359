import { Button, Text } from "@chakra-ui/react";
import React from "react";

const CustomButton = ({ title, handleFunction, btnShow, size }: any) => {
  return (
    <Button
      bgImage="url(img/header-button.svg)"
      bgSize={"100% 100%"}
      padding={size ? "2vw 3.2vw" : "3vw 3.5vw 2.7vw 3vw"}
      bgColor={"transparent"}
      fontWeight={"normal"}
      textAlign={"center"}
      onClick={handleFunction}
      _hover={{
        bgImage: "url(img/header-button.svg)",
        bgSize: "100% 100%",
        bgColor: "transparent",
      }}
    >
      <Text
        fontSize={size ? "16px" : "2vw"}
        lineHeight={size ? "23px" : "1.5vh"}
        letterSpacing="0"
        color="#63C4CE"
        opacity="1"
        textShadow={"0 0 10px #63C4CE, 0 0 20px #63C4CE, 0 0 30px #63C4CE"}
      >
        {title}
      </Text>
    </Button>
  );
};

export default CustomButton;
