import { Box, Center, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import useScrollSnap from "react-use-scroll-snap";

const Quote = (props: any) => {
  const { goto } = useScrollSnap({ ref: props.scrollRef, duration: 50 });
  return (
    <>
      <Box
        bgImage="url(img/BG.png)"
        bgRepeat="no-repeat"
        bgSize="cover"
        minH="100vh"
        maxH="100vh"
        position="relative"
        _before={{
          content: `""`,
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          background: "rgba(0, 0, 0, 0.875)",
        }}
      >
        <Center pos="relative" h="100vh" p="15px">
          <Flex
            pos="absolute"
            top="0"
            px="30px"
            cursor="pointer"
            onClick={() => goto(0)}
          >
            <Image
              src="img/top-line.png"
              alt="top-line"
              maxH={"260px"}
              height="100px"
            />
          </Flex>

          <Image src="img/quote-section-img.svg" h="50vh" />
          <Text
            fontSize={"16px"}
            fontFamily={"Nitti Typewriter"}
            color={"#BEBEBE"}
            pos={"absolute"}
            bottom="0"
            left="55%"
            mb="5%"
          >
            we overestimate the matter
          </Text>
          <Flex
            pos="absolute"
            bottom="0"
            px="30px"
            cursor="pointer"
            onClick={() => goto(2)}
          >
            <Image
              src="img/bottom-line.png"
              alt="bottom-line"
              maxH={"260px"}
              height={"100px"}
            />
          </Flex>
        </Center>
      </Box>
    </>
  );
};

export default Quote;
