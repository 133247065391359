import { Box, Button, Flex } from "@chakra-ui/react";
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

import { useNavigate } from "react-router-dom";
import {dataLoaded, dataError} from "../store/slices/graphSlice";


const AddNewEntry = () => {
  const navigate = useNavigate();

  return (
    <Box
      bgImage="url(img/BG.png)"
      width={"full"}
      height={"full"}
    
      style={{ overflow: "hidden" }}
    >
      {/* Dark overlay */}
      <Box
        position="absolute"
        top="0"
        left="0"
        width="full"
        height="full"
        bg="rgba(0, 0, 0, 0.875)"
       
      ></Box>
      <Flex justifyContent={"center"} alignItems={"center"} minHeight={"100vh"}>
        

        <Button
          _hover={{ backgroundColor: "transparent" }}
          backgroundColor="transparent"
          color="#BEBEBE"
          letterSpacing="3px"
          opacity="1"
          textAlign="left"
          fontFamily="Bauhaus Std"
          fontWeight="medium"
          fontSize="20px"
          border="none"
          position="relative"
          onClick={() => navigate("/dailyNewEntry")}
        >
          <span>add new entry</span>
          <span
            style={{
              position: "absolute",
              width: "82%",
              borderBottom: "1px solid #BEBEBE",
              bottom: "0.5rem",
            }}
          ></span>
        </Button>
      </Flex>
    </Box>
  );
};

export default AddNewEntry;
