import { gql } from "@apollo/client";

export const LOAD_QUERRY = gql`
  {
    newEntryCreateds(orderBy: Contract_id, orderDirection: desc) {
      id
      Contract_id
      amount
      uri
      labels
      notation
    }
    saleSuccessfuls(orderBy: tokenId, orderDirection: desc) {
      tokenId
      soldAmount
      nftContract
    }

    saleCreateds(orderBy: tokenId, orderDirection: desc) {
      nftContract
      tokenId
    }
  }
`;
