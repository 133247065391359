import { Box, Flex, Heading, Text } from "@chakra-ui/react";

const FullNft = ({ nft }: any) => {
  return (
    <Box
      border={"1px solid #707070"}
      width={["300px", "320px", "400px"]}
      padding={["20px 15px", "38px 30px"]}
      color={"#BEBEBE"}
      background={"#0B0A08 0% 0% no-repeat padding-box"}
      // height="100%"
      minH="450px"
    >
      <Flex justifyContent={"space-between"}>
        <Heading fontSize={"13px"} fontWeight={"normal"}>
          {nft?.notation || ""}
        </Heading>
      </Flex>
      <Box
        textAlign={"left"}
        marginTop={"35px"}
        height={"40%"}
        overflow={"hidden"}
        fontSize={"1.5vh"}
      >
        <Box minH="78px">
          <Text marginBottom={"15px"}>{nft?.tweet || ""}</Text>
        </Box>

        <Box pos="relative" minH="172px">
          <Text marginBottom={"15px"}>{nft?.additional || " "}</Text>
        </Box>
      </Box>
      <Flex
        justifyContent={"center"}
        fontSize={"14px"}
        gap={"22px"}
        marginTop={"30px"}
      >
        {nft?.labels?.map((item: any, idx: number) => (
          <Text key={idx}>#{item}</Text>
        ))}
      </Flex>
    </Box>
  );
};

export default FullNft;
