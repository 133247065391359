import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  SimpleGrid,
  Text,
  Textarea,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAccount, useDisconnect } from "wagmi";
import { useDispatch, useSelector } from "react-redux";

////

import axios from "axios";
import { addNewEntry } from "../store/slices/entrySlice";
import { toast } from "react-toastify";
import { IPFS_BASE_URL } from "../config";

const apiKey = "df1ec1ed30e28f6e6bd2"; // Replace with your actual Pinata API key
const apiSecret =
  "815404991b69b40e5d2533e74ac818688ec07daf81e7e5960abc0891debe8213";

interface FormTypes {
  notation: string;
  tweet: string;
  additional: string;
  labels: string[];
  copies: number;
  price: string;
  isDefault: boolean;
  isCompleted: boolean;
}

const DailyEntry = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { newEntryForm } = useSelector((state: any) => state.entry);

  const [formData, setFormData] = useState<FormTypes>({
    notation: "",
    tweet: "",
    additional: "",
    labels: [],
    copies: 10,
    price: "0.002",
    isDefault: true,
    isCompleted: false,
  });
  const [character, setCharacter] = useState("");
  const [discipline, setDiscipline] = useState("");
  const [location, setLocation] = useState("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [imageUri, setImageUri] = useState("");
  const { address, isConnected } = useAccount();
  const { disconnect } = useDisconnect();
  const handleDefaultChange = () => {
    setFormData({
      ...formData,
      price: "0.002",
      isDefault: !formData?.isDefault,
    });
  };

  const handleCompleteChange = () => {
    setFormData({
      ...formData,
      isCompleted: !formData?.isCompleted,
    });
  };

  const handleImageClick = () => {
    document?.getElementById("fileInput")?.click();
  };

  const handleFileChange = (e: any) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const uploadImageToPinata = async () => {
    if (selectedFile) {
      const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`;
      const formData = new FormData();
      formData.append("file", selectedFile);
      try {
        const response = await axios.post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            pinata_api_key: apiKey,
            pinata_secret_api_key: apiSecret,
          },
        });

        // Handle the response from Pinata, which will contain the IPFS CID (Content Identifier) of the pinned file.
        // console.log('Uploaded to Pinata. IPFS CID:', `https://gateway.pinata.cloud/ipfs/${response.data.IpfsHash}`);
        const imageurl = `${IPFS_BASE_URL}/ipfs/${response.data.IpfsHash}`;
        setImageUri(imageurl);
      } catch (error) {
        console.error("Error uploading to Pinata:", error);
      }
    } else {
      console.error("No image selected for upload");
    }
  };

  useEffect(() => {
    if (!address) {
      navigate("/connect");
    } else if (newEntryForm) {
      setFormData({
        notation: newEntryForm?.notation,
        tweet: newEntryForm?.tweet,
        additional: newEntryForm?.additional,
        labels: newEntryForm?.labels,
        copies: newEntryForm?.copies,
        price: newEntryForm?.price,
        isDefault: newEntryForm?.price === "0.002" ? true : false,
        isCompleted: newEntryForm?.isCompleted,
      });
      if (newEntryForm?.labels?.length > 0) {
        setCharacter(newEntryForm?.labels?.[0]);
      }
    }
  }, []);

  useEffect(() => {
    if (selectedFile) {
      uploadImageToPinata();
    }
  }, [selectedFile]);

  useEffect(() => {
    if (address !== "0xaA1E0Cb485e2d8F608096369bbF44be652b7412a") {
      toast.error("Sorry Friend, this is not for you!");
      disconnect();
      navigate("/connect");
    }
  }, [address]);

  return (
    <Box
      bgImage="url(img/BG.png)"
      bgRepeat={"no-repeat"}
      bgSize={"cover"}
      width={"full"}
      height={"full"}
      style={{ overflow: "hidden" }}
    >
      {/* Dark overlay */}
      <Box
        position="absolute"
        top="0"
        left="0"
        width="full"
        height="full"
        bg="rgba(0, 0, 0, 0.875)"
        // zIndex="1"
      ></Box>
      <form
        onSubmit={(e) => {
          e.preventDefault();

          if (formData?.tweet?.length > 240) {
            toast.error("Tweet size should be of 240 Characters");
          } else if (formData?.labels?.length < 1) {
            toast.error("Please add at least one label");
          } else if (Number(formData?.copies) < 1) {
            toast.error("Please add at least one copy");
          } else {
            dispatch(
              addNewEntry({
                ...formData,
                imageUri,
                labels: formData?.labels,
                price: Number(formData?.price),
              })
            );
            navigate("/publish");
          }
        }}
      >
        <Box
          p={[
            "102px 15px 60px 26px",
            "102px 40px 60px 55px",
            "102px 80px 60px 140px",
            "102px 120px 60px 160px",
            "102px 156px 60px 264px",
          ]}
          position={"relative"}
        >
          <SimpleGrid
            columns={[1, 1, 1, 2]}
            spacing={["50px", "100px", "150px", "180px", "245px "]}
          >
            <Box>
              <Heading
                fontSize={"30px"}
                fontWeight={"medium"}
                fontFamily={"Bauhaus Std"}
                letterSpacing={"3px"}
                color={"#BEBEBE"}
                textAlign={"left"}
                marginBottom={"33px"}
              >
                daily entry
              </Heading>
              <FormControl>
                <Flex
                  fontFamily={"Nitti Typewriter"}
                  fontSize={"16px"}
                  marginBottom={"35px"}
                  justifyContent={"space-between"}
                  align={"center"}
                >
                  <FormLabel letterSpacing={"0.01px"} color={"#FEFCFD"}>
                    notation
                  </FormLabel>
                  <Input
                    required
                    value={formData?.notation}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        notation: e.target.value,
                      })
                    }
                    color={"#EBEBF2"}
                    w={"76%"}
                    h={"56px"}
                    border="1px solid #FFFFFF3B"
                    border-radius="4px"
                  />
                </Flex>
                <Box>
                  <Flex
                    fontFamily={"Nitti Typewriter"}
                    fontSize={"16px"}
                    justifyContent={"space-between"}
                  >
                    <FormLabel letterSpacing={"0.01px"} color={"#FEFCFD"}>
                      tweet
                    </FormLabel>
                    <Textarea
                      required
                      value={formData?.tweet}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          tweet: e.target.value,
                        })
                      }
                      color={"#EBEBF2"}
                      w={"76%"}
                      h={"145px"}
                      overflow={"hidden"}
                      border="1px solid #FFFFFF3B"
                      border-radius="4px"
                      resize={"none"}
                    />
                  </Flex>
                  <Text
                    marginBottom={"46px"}
                    textAlign={"right"}
                    fontFamily={"Nitti Typewriter"}
                    color={"#EBEBF2B3"}
                    fontSize={"12px"}
                    marginTop={"10px"}
                  >
                    {240 - formData?.tweet?.length || 0} of 240 chars. left
                  </Text>
                </Box>
                <Flex
                  fontFamily={"Nitti Typewriter"}
                  fontSize={"16px"}
                  marginBottom={"65px"}
                  justifyContent={"space-between"}
                >
                  <FormLabel letterSpacing={"0.01px"} color={"#FEFCFD"}>
                    additional
                  </FormLabel>
                  <Textarea
                    required
                    value={formData?.additional}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        additional: e.target.value,
                      })
                    }
                    color={"#EBEBF2"}
                    w={"76%"}
                    height={"340px"}
                    lineHeight={"30px"}
                    border="1px solid #FFFFFF3B"
                    border-radius="4px"
                    sx={{
                      "&::-webkit-scrollbar": {
                        width: "4px",

                        borderRadius: "10px",

                        background: "#FEFCFD 0% 0% no-repeat padding-box",
                        // backgroundColor: `rgba(0, 0, 0, 0.05)`,
                      },
                      "&::-webkit-scrollbar-thumb": {
                        // backgroundColor: `rgba(0, 0, 0, 0.05)`,
                        background: "#FEFCFD 0% 0% no-repeat padding-box",
                      },
                    }}
                  />
                </Flex>
                <Flex
                  fontFamily={"Nitti Typewriter"}
                  fontSize={"16px"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <FormLabel letterSpacing={"0.01px"} color={"#FEFCFD"}>
                    add media
                  </FormLabel>

                  <Flex
                    w={"76%"}
                    onClick={handleImageClick}
                    cursor="pointer"
                    align={"center"}
                    gap={"5px"}
                  >
                    <Image src="img/paperclip.svg" />
                    {selectedFile && (
                      <Text
                        fontFamily={"Nitti Typewriter"}
                        color={"#EBEBF2B3"}
                        fontSize={"12px"}
                      >
                        Selected file: {selectedFile?.name}
                      </Text>
                    )}
                  </Flex>
                  {/* Hidden file input */}
                  <input
                    type="file"
                    id="fileInput"
                    accept="image/*" // Set accepted file types
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </Flex>
              </FormControl>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={"70px"}
              justifyContent={"center"}
              alignItems={"flex-start"}
              position={"relative"}
            >
              <Flex
                gap={"22px"}
                fontSize={"16px"}
                fontFamily={"Nitti Typewriter"}
                justify={"center"}
                align={["flex-start", "flex-start", "center", "center"]}
                flexDir={["column", "column", "row", "row"]}
              >
                <FormLabel
                  color={"#FEFCFD"}
                  textDecoration={"underline"}
                  letterSpacing={"0.01px"}
                >
                  labels
                </FormLabel>
                <Input
                  color={"#A6A4A4"}
                  border={"1px solid #FFFFFF3B"}
                  borderRadius={"4px"}
                  height={"56px"}
                  value={character}
                  onChange={(e) => {
                    setCharacter(e.target.value);
                    setFormData({
                      ...formData,
                      labels: [e.target.value, discipline, location],
                    });
                  }}
                />
                <Input
                  color={"#A6A4A4"}
                  border={"1px solid #FFFFFF3B"}
                  borderRadius={"4px"}
                  height={"56px"}
                  value={discipline}
                  onChange={(e) => {
                    setDiscipline(e.target.value);
                    setFormData({
                      ...formData,
                      labels: [character, e.target.value, location],
                    });
                  }}
                />
                <Input
                  color={"#A6A4A4"}
                  border={"1px solid #FFFFFF3B"}
                  borderRadius={"4px"}
                  height={"56px"}
                  value={location}
                  onChange={(e) => {
                    setLocation(e.target.value);
                    setFormData({
                      ...formData,
                      labels: [character, discipline, e.target.value],
                    });
                  }}
                />
              </Flex>
              <Flex
                gap={"22px"}
                fontSize={"16px"}
                fontFamily={"Nitti Typewriter"}
                justify={"center"}
                align={["flex-start", "flex-start", "center", "center"]}
                flexDir={["column", "column", "row", "row"]}
              >
                <Flex align={"center"}>
                  <FormLabel color={"#FEFCFD"} textDecoration={"underline"}>
                    nr. of copies
                  </FormLabel>
                  <Input
                    color={"#7B7B7E"}
                    border={"1px solid #FFFFFF3B"}
                    borderRadius={"4px"}
                    fontSize={"14px"}
                    height={"56px"}
                    width={"61px"}
                    p={"20px"}
                    value={formData?.copies} // Ensure copies is a number and convert it to a string
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        copies: parseFloat(e.target.value) || 0,
                      })
                    } // Convert e.target.value to a number
                  />
                </Flex>
                <Flex align={"center"}>
                  <FormLabel
                    color={"#FEFCFD"}
                    fontSize={"16px"}
                    marginLeft={["0px", "10px", "10px", "60px"]}
                  >
                    isCompleted
                  </FormLabel>
                  <Checkbox
                    isChecked={formData?.isCompleted}
                    onChange={handleCompleteChange}
                    colorScheme="blue" // Customize the color scheme as needed
                    border={"1px solid #FFFFFF3B"}
                    borderRadius={"4px"}
                    size={"lg"}
                    mb={"9px"}
                  />
                </Flex>
              </Flex>
              <Flex
                gap={"22px"}
                fontFamily={"Nitti Typewriter"}
                align={["flex-start", "flex-start", "center", "center"]}
                flexDir={["column", "column", "row", "row"]}
              >
                <FormLabel
                  color={"#FEFCFD"}
                  fontSize={"16px"}
                  textDecoration={"underline"}
                >
                  price
                </FormLabel>

                <Flex align={"center"}>
                  <FormLabel
                    color={"#FEFCFD"}
                    fontSize={"16px"}
                    marginLeft={["0px", "10px", "10px", "60px"]}
                  >
                    default
                  </FormLabel>
                  <Checkbox
                    isChecked={formData?.isDefault}
                    onChange={handleDefaultChange}
                    colorScheme="blue" // Customize the color scheme as needed
                    border={"1px solid #FFFFFF3B"}
                    borderRadius={"4px"}
                    size={"lg"}
                    mb={"9px"}
                  />
                </Flex>
                <Flex align={"center"} gap={"10px"}>
                  <Input
                    color={"#7B7B7E"}
                    border={"1px solid #FFFFFF3B"}
                    borderRadius={"4px"}
                    fontSize={"14px"}
                    height={"56px"}
                    width={"61px"}
                    p={"6px"}
                    min={0}
                    step="any"
                    type="number"
                    readOnly={formData?.isDefault}
                    value={formData?.price?.toString()} // Ensure eth is a number and convert it to a string
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        price: e.target.value,
                      })
                    }
                  />
                  <FormLabel color={"#FEFCFD"}>matic</FormLabel>
                </Flex>
              </Flex>
            </Box>
          </SimpleGrid>
          <Box marginTop={"47"}>
            <Button
              border={"1px solid #FEFCFD"}
              borderRadius={"4px"}
              opacity={"0.5"}
              fontFamily={"Bauhaus Std"}
              letterSpacing={"1.5px"}
              color={"#FEFCFD"}
              textTransform={"uppercase"}
              fontWeight={"medium"}
              bg={"transparent"}
              width={"200px"}
              type="submit"
            >
              Next
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default DailyEntry;
