import { Box, Flex, Heading, Skeleton, Text } from "@chakra-ui/react";

const NewsSkeletonCard = (props: any) => {
  return (
    <Box
      border={"1px solid #707070"}
      width={["300px", "320px", "400px"]}
      padding={["20px 15px", "38px 30px"]}
      color={"#BEBEBE"}
      background={"#0B0A08 0% 0% no-repeat padding-box"}
    >
      <Flex justifyContent={"space-between"}>
        <Skeleton height="10px" width="50px" />
        <Flex align="center">
          #<Skeleton height="10px" width="30px" />/
          <Skeleton height="10px" width="30px" />
        </Flex>
      </Flex>
      <Box
        textAlign={"left"}
        marginTop={"35px"}
        height={"40%"}
        overflow={"hidden"}
        fontSize={"1.5vh"}
      >
        <Text marginBottom={"15px"}>
          <Skeleton height="70px" />
        </Text>

        <Box pos="relative">
          <Skeleton height="50px" mb="20px" />
          <Skeleton height="50px" />
        </Box>
      </Box>
      <Flex
        justifyContent={"center"}
        fontSize={"14px"}
        gap={"22px"}
        marginTop={"30px"}
      >
        <Flex align="center">
          #<Skeleton height="10px" width="30px" />
        </Flex>
        <Flex align="center">
          #<Skeleton height="10px" width="30px" />
        </Flex>
        <Flex align="center">
          #<Skeleton height="10px" width="30px" />
        </Flex>
      </Flex>
    </Box>
  );
};

export default NewsSkeletonCard;
