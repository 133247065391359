import { readContract } from "@wagmi/core";
import { contractData, fromWeiToDecimals } from "./web3-utils";
import { IPFS_BASE_URL, PINATA_BASE_URL } from "../config";
import { fetchDataFromIpfs } from "./fetchAllNFTs";
import { setEntries, toggleEntriesLoading } from "../store/slices/entrySlice";

export const fetchMyNFT = async (address: any, id: any, account: any) => {
  const res = await readContract({
    address: address,
    abi: contractData.NovelAbi,
    functionName: "uri",
    args: [id],
    account: account,
  });
  if (res) {
    const response = res?.toString();
    const replacedURI = response?.replace(IPFS_BASE_URL, PINATA_BASE_URL);
    const uriData = await fetchDataFromIpfs(replacedURI);
    const replacedImage = uriData?.image?.replace(
      IPFS_BASE_URL,
      PINATA_BASE_URL
    );
    return { ...uriData, image: replacedImage };
  } else {
    return;
  }
};

export const checkIsOwner = async (address: any, account: any, id: any) => {
  const res = await readContract({
    address: address,
    abi: contractData.NovelAbi,
    functionName: "balanceOf",
    args: [id, account],
  });
  return res;
};

export const fetchMyNFTs = async (
  entries: any,
  dispatch: any,
  account: any
) => {
  dispatch(toggleEntriesLoading(true));
  let tempArr: any = [];
  let nft: any;

  for (nft of entries) {
    try {
      let res = await fetchMyNFT(
        nft?.entry?.nftContract,
        nft?.entry?.tokenId,
        account
      );
      let checkOwner = await checkIsOwner(
        nft?.entry?.nftContract,
        nft?.entry?.tokenId,
        account
      );
      if (res && Number(fromWeiToDecimals(checkOwner)) > 0) {
        tempArr?.push({ isOwner: true, uriData: res, entry: nft?.entry });
      }
    } catch (e) {
      console.log("e", e);
    }
  }
  dispatch(toggleEntriesLoading(false));
  dispatch(setEntries(tempArr));
};
