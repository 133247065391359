import {
  Box,
  Button,
  Center,
  Flex,
  FormLabel,
  Heading,
  Image,
  Input,
  Text,
} from "@chakra-ui/react";
import React from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import useScrollSnap from "react-use-scroll-snap";
import MailchimpForm from "./MailchimpForm";

const Footer = (props: any) => {
  // mailchimp post url
  const postUrl =
    "https://tableaux.us12.list-manage.com/subscribe/post?u=35e4234ee494a8d3493132a8c&id=3c2dcd8ee7&f_id=005c44e0f0";

  const { goto } = useScrollSnap({ ref: props.scrollRef, duration: 50 });
  return (
    <>
      <Box
        bgImage="url(img/BG.png)"
        bgRepeat="no-repeat"
        bgSize="cover"
        minH="100vh"
        maxH="100vh"
        position="relative"
        _before={{
          content: `""`,
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          background: "rgba(0, 0, 0, 0.875)",
        }}
      >
        <Center pos="relative" h="100vh" p="15px">
          <Box
            pos="absolute"
            top="0"
            px="30px"
            cursor="pointer"
            onClick={() => goto(4)}
          >
            <Image
              src="img/top-line.png"
              alt="top-line"
              maxH={"260px"}
              height={"100px"}
            />
          </Box>
          <Box>
            <Box
              border={"1px solid #707070"}
              borderRadius={"30px"}
              background={"#0B0A08 0% 0% no-repeat padding-box"}
              color={"#BEBEBE"}
              padding={"5vh 3vh"}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={"50px"}
              mixBlendMode={"exclusion"}
            >
              <Heading
                fontSize={"3vh"}
                textAlign={"center"}
                fontWeight={"bold"}
                letterSpacing={"0px"}
                fontFamily={"Bauhaus Std"}
              >
                Subscribe
              </Heading>
              <Text
                textAlign={"center"}
                fontSize={"1.5vh"}
                margin={"auto"}
                width={["100%", "80%", "60%"]}
                fontFamily={"Nitti Typewriter"}
              >
                be notified whenever a new entry comes out. no spam - only good
                writing, and the truth.
              </Text>
              <MailchimpSubscribe
                url={postUrl}
                render={({ subscribe, status, message }) => (
                  <MailchimpForm
                    status={status}
                    message={message}
                    onValidated={(formData: any) => subscribe(formData)}
                  />
                )}
              />
            </Box>
            <Text
              marginTop={"8vh"}
              fontSize={"20px"}
              textAlign={"center"}
              color={"#BEBEBE"}
            >
              just another quote before you leave.
            </Text>
          </Box>
          <Flex
            mb="30px"
            bottom="0"
            pos="absolute"
            w={{ md: "60vw" }}
            borderTop={"3px solid #FFF"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box>
              <Image
                src="img/spider.svg"
                alt="spider"
                width={"20px"}
                height={"20px"}
                marginLeft={"10px"}
                mt="10px"
              />
            </Box>
            <Flex
              justifyContent={"flex-end"}
              alignItems={"center"}
              marginTop={"10px"}
            >
              <Image
                src="img/twitter.jpeg"
                alt="twitter icon"
                w={["15px", "20px"]}
                h={["15px", "20px"]}
              />
              <Image
                src="img/twitter.jpeg"
                alt="twitter icon"
                w={["15px", "20px"]}
                h={["15px", "20px"]}
              />

              <Image
                src="img/twitter.jpeg"
                alt="twitter icon"
                w={["15px", "20px"]}
                h={["15px", "20px"]}
                mr="20px"
              />
              <Text fontSize={"1.5vh"} color={"#BEBEBE"}>
                @ Tableaux Vivants
              </Text>
            </Flex>
          </Flex>
          <Box position="absolute" left="0" bottom="0">
            <Image src="img/ant.svg" alt="ant" maxW={["80px", "164px"]} />
          </Box>
        </Center>
      </Box>
    </>
  );
};

export default Footer;
