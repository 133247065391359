import { Box, Center, Image, Text } from "@chakra-ui/react";
import { useState } from "react";

export const CustomCarousel = () => {
  const [elems, setElems] = useState([
    {
      id: 1,
      pos: "-1",
      title: "distribution",
      description1:
        "Breaking new ground in the realm of literary distribution, “Tableaux Vivants” is an ever-expanding novel made of daily NFT entries.",
      description2:
        "Each entry is a collectible piece of the narrative - introducing characters, situations, and even scientific discoveries in tweet-sized formats.",
      description3:
        "These entries are not just readable, they are ownable, tradeable, and composable.",
      description4:
        "This is not merely a novel, it is a new form of literary art - a decentralized narrative that leverages the power of decentralized technology to redefine what storytelling can be.",
      imageSrc: "img/page.svg",
    },
    {
      id: 2,
      pos: "0",
      title: "plot",
      description1:
        "The story unfolds in a village held captive by its own bodily waste, a currency that sustains a school for prodigious children. These children are trained to be emotionless entertainers, where their education is a cruel paradox that strips them of their humanity.",
      description2:
        "The rest of the village, meanwhile, exists in a state of perpetual misery, their lives reduced to mere cogs in this senseless machine.",
      description3:
        "This is not just another dystopian tale; it is a stark, realistic exploration of the absurdities that define our own world, told in a way that defies all literary conventions.",
      imageSrc: "img/page.svg",
    },
    {
      id: 3,
      pos: "1",
      title: "format",
      description1:
        "Unlike any novel before it, ‘Tableaux Vivants’ is a composable, non-fungible narrative that challenges the very notion of storytelling.",
      description2:
        " With its daily entries, Tableaux Vivants is not a static work of fiction; it is an ever-evolving literary experience.",
      description3:
        "Its innovative format allows the narrative to expand and adapt, offering new perspectives and layers of meaning with each entry.",
      description4:
        "It invites the reader to engage in a unique way, making each interaction not just a passive act of consumption, but a step in a journey that has no predetermined end.",
      imageSrc: "img/page.svg",
    },
  ]);

  const update = (newActive: any) => {
    const newActivePos = newActive.pos;
    let current = elems.find((elem) => elem.pos === "0");
    let prev = elems.find((elem) => elem.pos === "-1");
    let next = elems.find((elem) => elem.pos === "1");

    if (newActivePos === "-1") {
      const nextBackup = next;
      const prevBackup = prev;
      const currentBackup = current;

      if (next && currentBackup) {
        next = currentBackup;
        next.pos = "1";
      }

      if (current && prevBackup) {
        current = prevBackup;
        current.pos = "0";
      }

      if (prev && nextBackup) {
        prev = nextBackup;
        prev.pos = "-1";
      }
    } else {
      const nextBackup = next;
      const prevBackup = prev;
      const currentBackup = current;
      // debugger;
      if (next && prevBackup) {
        next = prevBackup;
        next.pos = "1";
      }

      if (current && nextBackup) {
        current = nextBackup;
        current.pos = "0";
      }

      if (prev && currentBackup) {
        prev = currentBackup;
        prev.pos = "-1";
      }
    }

    let updatedElem: any = [prev, current, next];

    setElems([...updatedElem]);

    // setElems([...elems]);
  };

  const handleItemClick = (item: any) => {
    if (item?.pos !== "0") {
      update(item);
    }
  };

  return (
    <>
      <div className="carousel">
        <ul className="carousel__list">
          {elems.map((item, idx) => (
            <li
              key={item?.id}
              onClick={() => handleItemClick(item)}
              className={`carousel__item ${
                item.pos === "0" ? "carousel__item_active" : ""
              }`}
              data-pos={item.pos}
            >
              <Center h="100%">
                <Box>
                  <Text
                    fontSize="1.8vh"
                    fontWeight={"bold"}
                    color="#202126"
                    fontFamily={"Bauhaus Std"}
                    marginBottom={["5px", "10px", "25px"]}
                    textAlign="center"
                  >
                    {item?.title}
                  </Text>
                  <Text
                    textAlign="left"
                    fontSize="1.4vh"
                    fontFamily={"Nitti Typewriter"}
                    marginBottom={["5px", "10px", "25px"]}
                  >
                    {item?.description1}
                  </Text>
                  <Text
                    fontSize="1.4vh"
                    textAlign="left"
                    fontFamily={"Nitti Typewriter"}
                    marginBottom={["5px", "10px", "25px"]}
                  >
                    {item?.description2}
                  </Text>
                  <Text
                    fontSize="1.4vh"
                    textAlign="left"
                    fontFamily={"Nitti Typewriter"}
                    marginBottom={["5px", "10px", "25px"]}
                  >
                    {item?.description3}
                  </Text>
                  <Text
                    fontSize="1.4vh"
                    textAlign="left"
                    fontFamily={"Nitti Typewriter"}
                    marginBottom={["5px", "10px", "25px"]}
                  >
                    {item?.description4}
                  </Text>
                  {item.pos === "0" && (
                    <Box pos="absolute" right="0" bottom="0" mr="50px" mb="6vh">
                      <Image
                        src="img/character.svg"
                        alt="character"
                        maxH="60px"
                      />
                    </Box>
                  )}
                </Box>
              </Center>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};
