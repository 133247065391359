// saleSuccessfulSlice.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  nftContract: null,
  soldAmount: null,
  tokenId: null,
};

const saleSuccessfulSlice = createSlice({
  name: "saleSuccessful",
  initialState,
  reducers: {
    setSaleSuccessfulData: (state, action) => {
      if (action?.payload) {
        const { nftContract, soldAmount, tokenId } = action?.payload;
        state.nftContract = nftContract;
        state.soldAmount = soldAmount;
        state.tokenId = tokenId;
      }
    },
  },
});

export const { setSaleSuccessfulData } = saleSuccessfulSlice.actions;

export default saleSuccessfulSlice.reducer;
