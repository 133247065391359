import { setEntries, toggleEntriesLoading } from "../store/slices/entrySlice";
import { setSaleData } from "../store/slices/saleSlice";
import { setSaleSuccessfulData } from "../store/slices/saleSuccessfullSlice";
import { IPFS_BASE_URL, PINATA_BASE_URL } from "../config";
import axios from "axios";

export const fetchDataFromIpfs = async (url: any) => {
  try {
    const response = await axios.get(url);
    if (response.status === 200) {
      return response.data;
    } else {
      // throw new Error(`IPFS request failed with status ${response?.status}`);
      return {};
    }
  } catch (error: any) {
    // throw new Error(`Error fetching data from IPFS: ${error?.message}`);
  }
};

export const fetchAllNFTs = (data: any, loading: boolean, dispatch: any) => {
  dispatch(toggleEntriesLoading(loading));
  const mergedData: any = {};

  data?.saleCreateds?.forEach((sale: any) => {
    const tokenId = sale?.tokenId;
    if (!mergedData[tokenId]) {
      mergedData[tokenId] = {
        tokenId: tokenId,
      };
    }
    // Merge data from saleCreateds
    Object.assign(mergedData[tokenId], sale);
  });

  data?.newEntryCreateds?.forEach((newEntry: any) => {
    const Contract_id = newEntry.Contract_id;
    if (!mergedData[Contract_id]) {
      mergedData[Contract_id] = {
        Contract_id: Contract_id,
      };
    }
    // Merge data from newEntryCreateds
    Object.assign(mergedData[Contract_id], newEntry);
  });

  data?.saleSuccessfuls?.forEach((saleSuccessful: any) => {
    const tokenId = saleSuccessful?.tokenId;
    const Contract_id = saleSuccessful?.nftContract;

    // Check if tokenId and Contract_id exist in mergedData
    if (!mergedData[tokenId]) {
      mergedData[tokenId] = {
        soldAmount: saleSuccessful?.soldAmount,
      };
    }

    Object.assign(mergedData[tokenId], saleSuccessful);
  });

  const mergedArray = Object.values(mergedData);

  //sorting the data in desending order
  mergedArray.sort(
    (a: any, b: any) => parseInt(b.Contract_id) - parseInt(a.Contract_id)
  );

  dispatch(setSaleData(data?.saleCreateds));
  dispatch(setSaleSuccessfulData(data?.saleSuccessfuls));

  if (mergedArray && Array.isArray(mergedArray)) {
    const fetchPromises: any = mergedArray.map(async (entry: any) => {
      const replacedURI = entry?.uri?.replace(IPFS_BASE_URL, PINATA_BASE_URL);
      const uriData = await fetchDataFromIpfs(replacedURI);
      const replacedImgURI = uriData?.image?.replace(
        IPFS_BASE_URL,
        PINATA_BASE_URL
      );
      return {
        uriData: {
          ...uriData,
          image: replacedImgURI,
        },
        entry: {
          ...entry,
          uri: replacedURI,
        },
      };
    });

    Promise.all(fetchPromises)
      .then((uris: any) => {
        dispatch(setEntries(uris));
      })
      .catch((error) => {
        console.error("Error fetching data from IPFS:", error);
      });
  }
};
