// dataSlice.js
import { createSlice } from "@reduxjs/toolkit";

const dataSlice = createSlice({
  name: "data",
  initialState: {
    newEntryCreateds: [],
    novelTableauxVivantsCreateds: [],
    loading: false,
    error: null,
  },
  reducers: {
    dataLoading: (state) => {
      state.loading = true;
      state.error = null;
    },
    dataLoaded: (state, action) => {
      state.newEntryCreateds = action.payload.newEntryCreateds;
      state.novelTableauxVivantsCreateds =
        action.payload.novelTableauxVivantsCreateds;
      state.loading = false;
    },
    dataError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const { dataLoading, dataLoaded, dataError } = dataSlice.actions;
export default dataSlice.reducer;
